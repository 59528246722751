body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
}

h1 {
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 0px;
}

.Container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin:auto;
    background-color: lightsteelblue;
}

.InnerContainer {
    max-width: 1600px;
    margin: auto;
    display: inherit;
    background-color: whitesmoke;

}

.Header {
    height: 150px;
    background-color: slategray;
    font-size: 56px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
}

.Header img {
    width: 100%;
    max-width: 450px;
}

.Footer {
    height: 110px;
    background-color:lightslategray;
    padding-right: 35px;
    display: flex;
    font-size: 8px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Footer p {
    margin-left: auto;
    margin-top: 0px;
    margin-bottom: 0px;
}

.Synopsis {
    padding-left: 35px;
    padding-right: 35px;
    align-items: center;
    display: flex;
    height: 100%;
    background-color: whitesmoke;
}

.SynopsisInner {
    max-width: 640px;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 35px;
}

.Dashboard {
    padding-left: 35px;
    padding-right: 35px;
    background-color: silver;
    padding-top: 15px;
}

.DashboardInner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 960px;
    margin-right: auto;
}

.DashboardCard {
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    padding-left: 25px;
    padding-right: 25px;
    border: 1px solid silver;
    display: flex;
    background-color: whitesmoke;
}

.DashboardRow {
    display: flex;
    justify-content: space-between;
}

.DashboardCentered {
    margin-left: auto;
    margin-right: auto;
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding: 0px !important;
}

.ChartContainer {
    margin-top: -50px;
}

.ChartContainerInner {
    max-height: 565px;
    width: 565px;
}

.ChartButtonBar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 565px;
}

.Spacer {
    height: 36px;
}

.SmallSpacer {
    height: 12px;
}

.ChartButtonBar button {
    border: 1px solid midnightblue;
    padding: 7px;
    background-color: steelblue;
    color: white;
    width: 95px;
    cursor: pointer;
}

.ChartButtonBar button:disabled {
    color: silver;
    font-style: italic;
    cursor: auto;
}

.DefinitionPart {
    font-size: 12px;
}

.DefinitionText {
    margin-left: 20px;
}

.MuiSlider-root {
    color: steelblue !important;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
    .ChartContainerInner {
        max-height: 565px;
        width: 100%;
    }

    .ChartContainer {
        margin-top: 0px;
    }
}

@media screen and (max-width: 600px) {
    .ChartContainerInner {
        width: 100%;
        padding-top: 0px;
    }
    
    .ChartContainer {
        margin-top: 0px;
    }    

    .Footer {
        padding-right: 5px;
    }

    .Footer p {
        margin-left: auto;
        margin-right: auto;   
    }

    .ChartButtonBar {
        width: 100%;
    }

    .Dashboard {
        padding: 20px;
    }
}
